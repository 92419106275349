.consultant-card {
  display: flex;
  justify-content: space-between;
}

.column {
  flex: 1;
  padding: 10px;
}
.row {
  margin: 10px 0px;
}

/* .input-field {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  } */

/* .input-field:focus {
    outline: none;
    border-color: #007bff;
  } */
/* Style for labels */
