/* .comanheadercolor {
  background-color: "#F2F2F2";
} */
.btn-mycandi {
  box-shadow: none !important;
  border-radius: 5px;
  font-weight: 500;
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  background-color: #5156bee8;
  padding: 0 40px;
  outline: none !important;
  overflow: hidden;
  display: inline-block;
  position: relative;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.5px;
  border: 0;
}
.btn-mycandibtn2 {
  box-shadow: none !important;
  border-radius: 5px;
  font-weight: 500;
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  background-color: #5156bee8;
  padding: 0 30px;
  outline: none !important;
  overflow: hidden;
  display: inline-block;
  position: relative;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.5px;
  border: 0;
}
.CandidateCard {
  /* background-color: #5156be; */
  border: 1px solid #5156be;
}

.cards {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin: 20px;
}

.column1 {
  background-color: #5156be;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-image img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 2px solid #fff;
}

.column2 {
  flex: 1;
  padding: 20px;
}

/* Add additional styling based on your preferences */
