:root {
  /* primary color */
  --prinary_color1: #26389a;
  --prinary_color2: #2e3c9f;
  --prinary_color3: #3440a5;
  --prinary_color4: #3b45aa;
  --prinary_color5: #414aaf;
  --prinary_color6: #474eb5;
  --prinary_color7: #4d53ba;

  /* secondary color */
  --secondary-color: #aabbcc;
  --secondary-shade-1: #bbcce1;
  --secondary-shade-2: #ccdde6;
  --secondary-shade-3: #ddeeee;
  --secondary-shade-4: #eeffff;
  --secondary-shade-5: #f5faff;
  --secondary-shade-6: #fbfdff;
  --secondary-shade-7: #ffffff;
  --secondary-shade-8: #f8f8f8;
  --secondary-shade-9: #f5f5f5;
  --secondary-shade-10: #f2f2f2;

  /* heading font-size h1-h2 */
  --h1-font-size: 36px;
  --h2-font-size: 32px;
  --h3-font-size: 28px;
  --h4-font-size: 24px;
  --h5-font-size: 20px;
  --h6-font-size: 16px;

  /* paragraph-font-size */
  --paragraph-font-size: 16px;

  /* span-font-size */
  --span-font-size: 14px;

  /* link-font-size */
  --link-font-size: 16px;

  /* strong-font-size */
  --strong-font-size: 18px;
}

/* Add candidate dropzone css start*/
.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.dropzone.active {
  border-color: #2196f3;
}

#upload-progress {
  margin-top: 20px;
}

#file-list {
  list-style-type: none;
  padding: 0;
}

#file-list li {
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 3px;
}

#file-list li.success {
  background-color: #a5d6a7; /* Green for success */
}

#file-list li.error {
  background-color: #ef9a9a; /* Red for error */
}

.file-status {
  margin-left: auto;
  font-weight: bold;
}
/* Add candidate dropzone css end*/

/* ---------------coman-------------------- */
.page-title {
  text-align: end;
}
/* ---------------coman--------------- */

/* ------------------dropzone----------- */
.dropzone {
  /* background-color: aliceblue !important; */
  border: "0px";
  border-radius: "4px";
  padding: "20px";
  text-align: "center";
  cursor: "pointer";
}

/* ------------------dropzone----------- */

/* ----------------twitter-bs-wizard -------------- */
.twitter-bs-wizard .twitter-bs-wizard-nav .step-icon {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 1px solid rgba(81, 86, 190, 0.2);
  color: #5156be;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  font-size: 20px;

  @media (max-width: 575.98px) {
    width: 40px;
    height: 40px;
    line-height: 38px;
  }
}

.twitter-bs-wizard .twitter-bs-wizard-nav .step-title {
  margin-left: 6px !important;
}

.twitter-bs-wizard
  .twitter-bs-wizard-nav
  .nav-item:last-child
  .nav-link::after {
  display: none !important;
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-item .nav-link.done .step-icon {
  background-color: #5156be !important;
  color: #fff !important;
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-item .nav-link.done .uil:before {
  content: "\e9c3" !important;
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link {
  font-size: 14px;
  position: relative;

  @media (max-width: 575.98px) {
    padding: 0.5rem !important;
  }
  /* 
  &::after {
    content: "" !important;
    position: absolute !important;
    width: 2px !important;
    height: 75% !important;
    background-color: gray !important;
    left: 50% !important;
    top: 80% !important;
    
    @media (max-width: 575.98px) {
      display: none !important;
    }
  } */

  &.active {
    color: #6c757d !important;
    background-color: transparent !important;
  }
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link::after {
  content: "" !important;
  position: absolute !important;
  width: 1px !important;
  height: 26px !important;
  background-color: gray !important;
  left: 50% !important;
  top: 105% !important;
  /* transform: translateY(-1px); */
  transform: translateY(-50%) !important;
}

.twitter-bs-wizard .twitter-bs-wizard-pager-link {
  padding-top: 24px;
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.twitter-bs-wizard .twitter-bs-wizard-pager-link li {
  display: inline-block;
}

.twitter-bs-wizard .twitter-bs-wizard-pager-link li.next {
  float: right;
}

.twitter-bs-wizard-tab-content {
  padding-top: 24px;
  min-height: 262px;
}

.form-vertical {
  height: auto;
}

/* ----------------twitter-bs-wizard -------------- */

.candidatecard {
  background-color: aliceblue !important;
}

/* //add candidate certification --------------- */
.marquee {
  white-space: nowrap;
  overflow: hidden;
  animation: marquee 8s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
